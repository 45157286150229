
export const GET_COUNTER = "/api/counter";
export const GET_LASTORDER = "/api/lastOrder";
export const POST_INSERT = "/api/upload";
export const GET_TOTAL = "/api/totalMonths/";
export const GET_INVENTORY_TABLE = "/api/inventoryTables/";
export const POST_FINISH_ORDER = "/api/orders";
export const POST_UPDATE_DATA = "/api/updateData";
export const GET_ALL_ORDERS = "/api/orders";
export const GET_BANNER_DATA = "/api/contentdata/banners";
export const GET_CARDS_DATA = "/api/contentdata/cards";
export const POST_CONTENT = "/api/contentEdit/";
export const POST_LOGIN = "/api/login"; 
export const POST_DELETE = "/api/delete/"
